import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { MapsIntro } from 'common/components/templates/MapsIntro'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface MapsIntroPageProps {
    pageContext: any
}

export const MapsIntroPage: React.FC<MapsIntroPageProps> = ({
    pageContext,
}) => {
    return <MapsIntro {...getMapsIntroData(pageContext)} />
}

export default MapsIntroPage

export const getMapsIntroData = (data: any) => ({
    layout: getLayoutData(data),
    jumbotron: getJumbotronData(data),
    maps: {
        mapTitle: data.maps_title,
        maps: (data.maps || []).map((map: any) => ({
            tabName: map.tab_name,
            title: map.title,
            description: map.description,
            factsheetUrl: map.factsheet_url,
            lastUpdated: map.last_updated,
            chartData: {
                data: (map.chart_data || []).map((chartData: any) => ({
                    name: chartData.display_text,
                    value: chartData.value,
                    color: chartData.color,
                })),
            },
        })),
    },
    resources: {
        title: data.resources__title,
        text: data.resources__text,
        subtitle: data.resources__subtitle,
        downloads: data.resources__downloads,
    },
    information: {
        title: data.title,
        text: (data.paragraphs || []).map(
            (paragraph: { text: string }) => paragraph.text
        ),
    },
    benefits: {
        benefits: (data.threecards__cards || []).map((card: any) => ({
            ...card,
            callToAction: {
                label: card.card_cta_0396ba7__cta_label,
                variant: card.card_cta_0396ba7__cta_variant,
                url: card.card_cta_0396ba7__cta_url,
            },
        })),
        subtitle: data.threecards__subtitle,
        title: data.threecards__title,
    },
    alerts: {
        alerts: data.alerts__alerts.map((alert: any) => alert.text),
    },
})
// Removed RL-2622
// const formatDate = (date: string) => {
//     const d = new Date(date)
//     const day = (d.getDate() < 10 ? '0' : '') + d.getDate()
//     const month = (d.getMonth() < 10 ? '0' : '') + d.getMonth()
//     const year = d.getFullYear().toString().substr(-2)
//     return `${day}/${month}/${year}`
// }
