import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Alerts, AlertsProps } from 'common/components/organisms/Alerts'
import { Benefits, BenefitsProps } from 'common/components/organisms/Benefits'
import {
    Information,
    InformationProps
} from 'common/components/organisms/Information'
import {
    InvestmentMap,
    InvestmentMapProps
} from 'common/components/organisms/InvestmentMap'
import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    Resources,
    ResourcesProps
} from 'common/components/organisms/Resources'
import React from 'react'

export interface MapsIntroProps {
    alerts: AlertsProps
    benefits: BenefitsProps
    information: InformationProps
    jumbotron: JumbotronProps
    maps: InvestmentMapProps
    layout: LayoutProps
    resources: ResourcesProps
}

export const MapsIntro: React.FC<MapsIntroProps> = ({
    alerts,
    benefits,
    information,
    jumbotron,
    layout,
    resources,
    maps
}) => {
    return (
        <Layout {...layout}>
            <Jumbotron {...jumbotron} />
            <Wrapper>
                <Spacer />
                <Information {...information} />
                <Resources {...resources} />
            </Wrapper>
            <InvestmentMap {...maps} />
            <Wrapper>
                {benefits.title && <Benefits {...benefits} />}
                <Spacer />
                <Alerts {...alerts} />
            </Wrapper>
        </Layout>
    )
}
