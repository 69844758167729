import { ChartDataProps } from 'common/components/organisms/InvestmentMap'
import React from 'react'
import { Cell, Pie, PieChart, Tooltip } from 'recharts'

export interface PieChartProps {
    data: ChartDataProps[]
}

export const Piechart: React.FC<PieChartProps> = ({ data }) => {
    return (
        <PieChart data-testid='Piechart' width={400} height={400}>
            <Pie
                data={data}
                cx={150}
                cy={200}
                labelLine={false}
                fill='#8884d8'
                dataKey='value'
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
            </Pie>
            <Tooltip />
        </PieChart>
    )
}
