import React from 'react'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'

export const TabPanel = (props:any) => {
    const { children, value, index, ...other } = props
    return (
        <div
            data-testid='TabPanel'
            role='tabpanel'
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
}