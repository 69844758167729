import { Box, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core'
import { firebaseTabsStylesHook } from '@mui-treasury/styles/tabs'
import { useLocation } from '@reach/router'
import { Piechart } from 'common/components/molecules/Piechart'
import { ProgressBar } from 'common/components/molecules/ProgressBar'
import { Spacer } from 'common/components/molecules/Spacer'
import { TabPanel } from 'common/components/molecules/Tabs'
import { Text } from 'common/components/molecules/Text'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { DownloadLink } from 'common/components/organisms/Resources/DownloadLink'
import { useDesktop } from 'common/hooks/useDesktop'
import React, { useEffect, useRef } from 'react'
import TagManager from 'react-gtm-module'
import SwipeableViews from 'react-swipeable-views'

export interface InvestmentMapProps {
    mapTitle: string
    maps: MapsProps[]
    ref?: object
}

export interface MapsProps {
    tabName: string
    title: string
    description: string
    factsheetUrl: string
    chartData: MapChartDataProps
    lastUpdated: string
}

export interface MapChartDataProps {
    data: ChartDataProps[]
}

export interface ChartDataProps {
    name: string
    value: number
    color: string
}

export const InvestmentMap: React.FC<InvestmentMapProps> = props => {
    const [value, setValue] = React.useState(0)
    const tabItemStyles = firebaseTabsStylesHook.useTabItem()

    let location = useLocation()
    const mapsRef: any = useRef(null)

    useEffect(() => {
        const selectMap = () => {
            // check for a hash
            if (location.hash.length > 0) {
                // get the length of the tab count coming form the CMS
                const tabCount = props.maps ? props.maps.length : 0
                // split the hash to get the map number
                const currentTab = parseInt(location.hash.split('#')[1])
                // check if the currnetTab is gretaer then the tab count
                if (tabCount > currentTab) {
                    // set the selected tab
                    setValue(currentTab)
                    // scroll to that tab
                    mapsRef.current.scrollIntoView({ behavior: 'smooth' })
                }
            }
        }
        selectMap()
    }, [props, location.hash])

    const useStyles = makeStyles({
        indicator: {
            backgroundColor: '#5261AC',
            height: 6,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5
        },
        tabPanel: {
            overflow: 'visible'
        },
        title: {
            color: '#2F9184',
            fontWeight: 600,
            marginBottom: '20px',
            fontSize: '16px',
            fontFamily: 'Assistant',
            lineHeight: 1.2,
            letterSpacing: '0.05em',
            textTransform: 'uppercase'
        },
        heading: {
            fontSize: '36px',
            lineHeight: '44px',
            marginBottom: '20px'
        },
        text: {
            fontSize: '20px',
            marginBottom: '20px'
        },
        progressWrapper: {
            background: '#FFFFFF',
            padding: '10px 20px'
        }
    })
    const classes = useStyles()
    const desktop = useDesktop()

    const handleChange = (event: any, newValue: number) => {
        setValue(newValue)
    }

    const handleChangeIndex = (index: number) => {
        setValue(index)
    }

    return (
        <div data-testid='InvestmentMap' ref={mapsRef}>
            <Box>
                <Wrapper>
                    <Spacer variant='md' />
                    <Box style={{ textAlign: 'center' }}>
                        <Typography
                            style={{
                                color: '#4DB7AB',
                                fontWeight: 600,
                                fontSize: 16,
                                lineHeight: 1,
                                textTransform: 'uppercase'
                            }}
                        >
                            PORTFOLIO
                        </Typography>
                        <Typography
                            style={{
                                color: '#3A3160',
                                fontWeight: 500,
                                fontSize: 36,
                                marginTop: 20,
                                lineHeight: '44px',
                                padding: '10px'
                            }}
                        >
                            {props.mapTitle}
                        </Typography>
                    </Box>
                    <Spacer variant='md' />
                    <Tabs
                        classes={{ indicator: classes.indicator }}
                        value={value}
                        variant='scrollable'
                        indicatorColor='primary'
                        textColor='primary'
                        onChange={(e, value) => {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'subNavigationClick',
                                    clickText: props.maps[value].tabName,
                                    clickURL: '',
                                    clickID: props.maps[value].tabName
                                }
                            })
                            handleChange(e, value)
                        }}
                    >
                        {props.maps &&
                            Object.values(props.maps).map((map, index) => (
                                <Tab
                                    key={index}
                                    classes={tabItemStyles}
                                    label={map.tabName}
                                    style={{
                                        width: '160px',
                                        textTransform: 'uppercase'
                                    }}
                                />
                            ))}
                    </Tabs>
                </Wrapper>
            </Box>
            <Box style={{ background: '#EBEDF7' }}>
                <Wrapper>
                    <Grid container style={{ background: '#EBEDF7' }}>
                        <Grid container item>
                            {props.maps && (
                                <SwipeableViews
                                    axis='x-reverse'
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    slideStyle={{ overflow: 'hidden' }}
                                >
                                    {props.maps &&
                                        Object.values(props.maps).map(
                                            (map, index) => {
                                                return (
                                                    <TabPanel
                                                        key={index}
                                                        value={value}
                                                        index={index}
                                                    >
                                                        <Spacer
                                                            variant={'sm'}
                                                        />
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: desktop
                                                                    ? 'row'
                                                                    : 'column',
                                                                justifyContent:
                                                                    'space-between'
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: desktop
                                                                        ? '25%'
                                                                        : '100%'
                                                                }}
                                                            >
                                                                <Text
                                                                    className={
                                                                        classes.title
                                                                    }
                                                                    label={
                                                                        map.tabName
                                                                    }
                                                                />
                                                                <Text
                                                                    className={
                                                                        classes.heading
                                                                    }
                                                                    label={
                                                                        map.title
                                                                    }
                                                                />
                                                                <Text
                                                                    className={
                                                                        classes.text
                                                                    }
                                                                    html
                                                                    label={
                                                                        map.description
                                                                    }
                                                                />
                                                                <DownloadLink
                                                                    text={`${map.tabName} Factsheet`}
                                                                    url={
                                                                        map.factsheetUrl
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width: desktop
                                                                        ? '33%'
                                                                        : '100%'
                                                                }}
                                                            >
                                                                <Piechart
                                                                    {...map.chartData}
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width: desktop
                                                                        ? '33%'
                                                                        : '100%'
                                                                }}
                                                            >
                                                                <Box
                                                                    className={
                                                                        classes.progressWrapper
                                                                    }
                                                                >
                                                                    <ProgressBar
                                                                        key={
                                                                            index
                                                                        }
                                                                        {...map.chartData}
                                                                    />
                                                                </Box>
                                                                {map.lastUpdated && (
                                                                    <Box
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            justifyContent:
                                                                                'flex-end',
                                                                            marginTop:
                                                                                '10px'
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px'
                                                                            }}
                                                                            label={`Last updated: ${map.lastUpdated}`}
                                                                        />
                                                                    </Box>
                                                                )}
                                                            </div>
                                                        </Box>
                                                    </TabPanel>
                                                )
                                            }
                                        )}
                                </SwipeableViews>
                            )}
                            <Spacer variant='md' />
                        </Grid>
                    </Grid>
                </Wrapper>
            </Box>
        </div>
    )
}
