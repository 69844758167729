import { MapChartDataProps } from 'common/components/organisms/InvestmentMap'
import React from 'react'

export const ProgressBar: React.FC<MapChartDataProps> = ({ data }) => {
    return (
        <>
            {data.map((item, index) => {
                return (
                    <div key={index} style={{ marginBottom: 15 }}>
                        <p style={{ marginBottom: 5 }}>{item.name}</p>
                        <div
                            style={{
                                height: 11,
                                width: '100%',
                                backgroundColor: '#e0e0de'
                            }}
                        >
                            <div
                                style={{
                                    width: `${item.value}%`,
                                    backgroundColor: item.color,
                                    height: '100%',
                                    borderRadius: 'inherit',
                                    textAlign: 'right'
                                }}
                            >
                                <span
                                    style={{
                                        padding: 5,
                                        color: 'white',
                                        fontWeight: 'bold'
                                    }}
                                ></span>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}
